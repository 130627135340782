'use client'

import { useLocale } from 'next-intl'
import * as Dropdown from '@hashicorp/react-hds/src/components/dropdown'
import type { ToggleButtonProps } from '@hashicorp/react-hds/src/components/dropdown/toggle/button'
import {
	LOCALE_LANGUAGE_MAP,
	SUPPORTED_LOCALES,
} from 'lib/i18n/helpers/constants'
import { usePathname } from 'lib/i18n/routing'

interface LocaleSwitcherProps {
	/**
	 * Overrides the text on the switcher's dropdown button
	 */
	buttonText?: string
	/**
	 * Provides a list of locales available for switching,
	 * which overrides the `SUPPORTED_LOCALES` list
	 */
	localesAvailable?: typeof SUPPORTED_LOCALES
	/**
	 * Moves the dropdown list according to one of the available listPosition values
	 */
	listPosition?: Dropdown.ContentProps['listPosition']
	/**
	 * Sets `primary` or `secondary` styling for the dropdown
	 */
	variant?: ToggleButtonProps['color']
}

export default function LocaleSwitcher({
	buttonText,
	localesAvailable,
	listPosition = 'left',
	variant = 'secondary',
}: LocaleSwitcherProps) {
	let pathname = usePathname()
	const locale = useLocale()

	const localeList =
		localesAvailable?.length > 0 ? localesAvailable : SUPPORTED_LOCALES

	return (
		<Dropdown.Root>
			<Dropdown.Toggle.Button
				icon="globe"
				text={buttonText || LOCALE_LANGUAGE_MAP[locale]}
				color={variant}
			/>
			<Dropdown.Content listPosition={listPosition} width="110%">
				<Dropdown.List>
					{localeList.map((supportedLocale) => {
						/**
						 * Re-assign the pathname on the homepage
						 * to prevent duplicated locales there
						 */
						if (pathname === '/') {
							pathname = '/'
						}

						return (
							<Dropdown.ListItem.Interactive
								key={supportedLocale}
								locale={supportedLocale}
								href={pathname}
								text={LOCALE_LANGUAGE_MAP[supportedLocale]}
								trailingIcon={locale === supportedLocale ? 'check' : undefined}
								prefetch={false}
							/>
						)
					})}
				</Dropdown.List>
			</Dropdown.Content>
		</Dropdown.Root>
	)
}
