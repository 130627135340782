import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import { TextPlus } from '@hashicorp/react-hds/src/components/text'
import type { SubmissionStatus } from 'components/materia/prefooter-newsletter-form'
import s from './style.module.css'

interface SubmitMessageProps {
	submitStatus: SubmissionStatus
}

export default function SubmitMessage({ submitStatus }: SubmitMessageProps) {
	const isSuccessful = submitStatus === 'success'
	const iconName = isSuccessful ? 'check-circle-fill' : 'alert-triangle'
	const iconColor = isSuccessful
		? 'var(--token-color-foreground-success)'
		: 'var(--token-color-foreground-warning)'

	return (
		<div className={s.submittedMsg} data-test-id="submitMessage">
			<FlightIcon name={iconName} color={iconColor} />
			<TextPlus.Body color="faint" size="200">
				{isSuccessful ? (
					'Thanks for subscribing!'
				) : (
					<>
						We’re having trouble getting you subscribed. Try again or reach out
						to{' '}
						<a href="mailto:hello@hashicorp.com" className={s.link}>
							hello@hashicorp.com
						</a>
					</>
				)}
			</TextPlus.Body>
		</div>
	)
}
