'use client'

import { Link } from 'lib/i18n/routing'
import classNames from 'classnames'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import { vercelSplit } from '@web/cms'
import type { SocialNetworkBlockFieldsFragment } from 'types/dato'
import s from './style.module.css'

interface SocialBlockProps extends SocialNetworkBlockFieldsFragment {
	className?: string
}

export default function SocialBlock({
	socialItems,
	className,
}: SocialBlockProps) {
	return (
		<ul className={classNames(s.links, className)}>
			{socialItems.map(({ href, name, icon }) => (
				<li key={name}>
					<Link href={href} className={s.link}>
						<VisuallyHidden>{name}</VisuallyHidden>
						<FlightIcon
							name={vercelSplit(icon.name)}
							color="var(--token-color-foreground-disabled)"
							size={24}
						/>
					</Link>
				</li>
			))}
		</ul>
	)
}
