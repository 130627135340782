import { supportedLocales } from '@web/utils/supported-locales'

export const SUPPORTED_LOCALES = ['en', ...supportedLocales]

export const LOCALE_LANGUAGE_MAP = {
	en: 'English',
	fr: 'Français',
	de: 'Deutsch',
	ja: '日本語',
	ko: '한국어',
	pt: 'Português',
	es: 'Español',
}

export const TRANSLATION_ALERT_STATE_MAP = {
	en: {
		translationUnavailable: {
			title: 'Translation unavailable',
			description:
				'Unfortunately we do not have translated version of this page available for your language yet. Please check again later.',
			localeSwitcher: {
				text: 'Available languages',
			},
		},
		translationAvailable: {
			title: 'Translation available',
			description:
				'There is a translated version of this page available for your language.',
			cta: { text: 'Visit translated page' },
		},
		languageUnsupported: {
			title: 'Language not supported',
			description:
				'Unfortunately we do not support your language on our site yet. Please check again later.',
			localeSwitcher: {
				text: 'Available languages',
			},
		},
	},
	fr: {
		translationUnavailable: {
			title: 'Traduction non disponible',
			description:
				"Malheureusement, nous n'avons pas encore de version traduite de cette page dans votre langue. Veuillez vérifier à nouveau ultérieurement.",
			localeSwitcher: {
				text: 'Langues disponibles',
			},
		},
		translationAvailable: {
			title: 'Traduction disponible',
			description:
				'Une version traduite de cette page est disponible dans votre langue.',
			cta: { text: 'Visiter la page traduite' },
		},
	},
	de: {
		translationUnavailable: {
			title: 'Übersetzung nicht verfügbar',
			description:
				'Leider haben wir noch keine übersetzte Version dieser Seite für Ihre Sprache zur Verfügung. Bitte schauen Sie später noch einmal nach.',
			localeSwitcher: {
				text: 'Verfügbare Sprachen',
			},
		},
		translationAvailable: {
			title: 'Übersetzung verfügbar',
			description:
				'Es ist eine übersetzte Version dieser Seite für Ihre Sprache verfügbar.',
			cta: { text: 'Übersetzte Seite besuchen' },
		},
	},
	ja: {
		translationUnavailable: {
			title: '翻訳不能',
			description:
				'残念ながら、このページの翻訳版はまだありません。後ほどご確認ください。',
			localeSwitcher: {
				text: '使用可能な言語',
			},
		},
		translationAvailable: {
			title: '翻訳あり',
			description:
				'このページには、あなたの言語に翻訳されたバージョンがあります。',
			cta: { text: '翻訳されたページを見る' },
		},
	},
	ko: {
		translationUnavailable: {
			title: '번역할 수 없음',
			description:
				'안타깝게도 아직 이 페이지의 번역본이 귀하의 언어로 제공되지 않습니다. 나중에 다시 확인해 주세요.',
			localeSwitcher: {
				text: '사용 가능한 언어',
			},
		},
		translationAvailable: {
			title: '번역 가능',
			description: '이 페이지의 번역본이 귀하의 언어로 제공되고 있습니다.',
			cta: { text: '번역된 페이지 방문' },
		},
	},
	pt: {
		translationUnavailable: {
			title: 'Tradução não disponível',
			description:
				'Infelizmente, ainda não dispomos de uma versão traduzida desta página para a sua língua. Por favor, verifique novamente mais tarde.',
			localeSwitcher: {
				text: 'Línguas disponíveis',
			},
		},
		translationAvailable: {
			title: 'Tradução disponível',
			description:
				'Está disponível uma versão traduzida desta página para a sua língua.',
			cta: { text: 'Visitar a página traduzida' },
		},
	},
	es: {
		translationUnavailable: {
			title: 'Traducción no disponible',
			description:
				'Lamentablemente, aún no disponemos de una versión traducida de esta página a su idioma. Vuelva a comprobarlo más tarde.',
			localeSwitcher: {
				text: 'Idiomas disponibles',
			},
		},
		translationAvailable: {
			title: 'Traducción disponible',
			description:
				'Existe una versión traducida de esta página para su idioma.',
			cta: { text: 'Visite la página traducida' },
		},
	},
}
