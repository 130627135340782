'use client'

import { m } from 'framer-motion'
import { useState } from 'react'
import { SubmissionStatus } from '..'
import SubmitMessage from '../submit-message'
import MarketoForm from '@hashicorp/react-components/src/components/marketo-form'
import { marketoFormData } from 'components/newsletter-signup-form/marketoFormData'
import s from './styles.module.css'

export const Submit = () => {
	const [submissionStatus, setSubmissionStatus] =
		useState<SubmissionStatus | null>(null)

	const onSubmitSuccess = () => {
		setSubmissionStatus('success')
		if (window && window.analytics) {
			window.analytics.track('Newsletter Signup', { placement: 'footer' })
		}
	}

	const onSubmitError = () => {
		setSubmissionStatus('error')
	}
	return (
		<>
			{submissionStatus && (
				<m.div
					className={s.submissionStatus}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.3, delay: 0.2 }}
				>
					<SubmitMessage submitStatus={submissionStatus} />
				</m.div>
			)}
			<m.div
				inert={submissionStatus && true}
				animate={{ opacity: submissionStatus ? 0 : 1 }}
				transition={{ duration: 0.3 }}
			>
				<MarketoForm
					{...marketoFormData}
					submitTitle="Subscribe"
					className={s.form}
					onSubmitSuccess={onSubmitSuccess}
					onSubmitError={onSubmitError}
					persistFieldValues={false}
					formName="Sign up for HashiCorp news"
				/>
			</m.div>
		</>
	)
}
